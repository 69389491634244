@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
body {
  @apply bg-[#0a0a0a];
}

/* Component styles */
@layer components {
  /* Layout Components */
  .container {
    @apply min-h-screen flex flex-col items-center justify-center relative px-5;
  }

  .main-content {
    @apply bg-white pt-24 min-h-screen;
  }

  .page-container {
    @apply max-w-5xl mx-auto px-4 py-8;
  }

  /* Navigation Components */
  .nav-wrapper {
    @apply bg-[#0a0a0a] w-full;
  }

  .top-menu {
    @apply fixed top-0 left-0 right-0 p-5 opacity-0 z-50 bg-[#0a0a0a];
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 3.5s;
  }

  .nav-links {
    @apply flex justify-center list-none gap-10;
  }

  .nav-links a {
    @apply text-white no-underline font-playfair text-lg transition-colors duration-300 hover:text-[#D4AF37];
  }

  .golden-line {
    @apply fixed top-[80px] h-px bg-[#706c5c] w-0 opacity-0 z-40;
    animation: 
      fadeIn 1s ease-out forwards,
      drawLine 1.5s ease-out forwards;
    animation-delay: 2s;
  }

  /* Home Page Components */
  .signature {
    @apply max-w-[60%] md:max-w-[40%] opacity-0;
    animation: fadeIn 4s ease-out forwards;
  }

  .intro-text {
    @apply font-playfair text-white text-lg md:text-xl text-right max-w-[500px] leading-relaxed p-5 md:p-20 opacity-0;
    animation: fadeIn 1s ease-out forwards;
    animation-delay: 5.5s;
  }

  /* Page Components */
  .page-title {
    @apply text-3xl md:text-4xl font-playfair text-[#333333] mb-8 text-center;
  }

  /* Animation Utility Classes */
  .animate-fadeIn {
    @apply opacity-0;
    animation: fadeIn 1s ease-out forwards;
  }

  .delay-200 {
    animation-delay: 200ms;
  }

  .delay-400 {
    animation-delay: 400ms;
  }

  .delay-600 {
    animation-delay: 600ms;
  }
}

/* Mobile Menu Styles */
.hamburger {
  @apply hidden flex-col cursor-pointer gap-1.5 p-2.5 md:hidden;
}

.hamburger span {
  @apply block w-6 h-0.5 bg-[#D4AF37] transition-all duration-300;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes drawLine {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .hamburger {
    @apply flex;
  }

  .nav-links {
    @apply hidden absolute top-20 left-0 w-full bg-[#0a0a0a] flex-col items-start p-5 gap-5 border-t border-white;
  }

  .nav-links.show {
    @apply flex;
  }

  .hamburger.active span:nth-child(1) {
    @apply rotate-45 translate-y-2;
  }

  .hamburger.active span:nth-child(2) {
    @apply opacity-0;
  }

  .hamburger.active span:nth-child(3) {
    @apply -rotate-45 -translate-y-2;
  }
}